export const rules = {
  name: [
    {
      required: true,
      message: '商品名是必填内容',
      trigger: 'change'
    }
  ],
  price: [
    {
      required: true,
      message: '价格是必填内容',
      trigger: 'change'
    },
    {
      pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
      message: '价格类型为数字',
      trigger: 'change'
    }
  ]
}
